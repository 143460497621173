/* eslint-disable max-len */
import React, { Component } from 'react';
import styles from './categorypage.scss';
import ArticleTileGrid from '../articleTileGrid/article-tile-grid';
import FragmentPlaceholder from '../../Common/fragment-placeholder/fragment-placeholder';
import { ICategoryPageContext, ICategoryPageState } from './categorypage.d';
import FasBar from '../fas-bar/fas-bar';
import StickyHeader from '../stickyHeader/stickyHeader';
import { ArticleTilesAjaxCaller } from '../articleTileGrid/ArticleTilesAjaxCaller';
import { GlobalState } from '../globalState/globalState';
import { ArticleListController } from '../globalState/ArticleListController';
import { convertFromServiceToReactWorld } from '../globalState/ServiceSelectedFilterConverter';
import { IFilteringResult, ISalesDesignationView } from '../articleTileGrid/article-tile/article-tile.d';
import Paging from '../paging/paging';
import * as Constants from '../../Common/constants';
import FasButton from '../fas-button/fas-button';
import classnames from 'classnames';
import MainSidePanel from '../mainSidePanel/mainSidePanel';
import * as helper from '../../Common/html-helper';
import ProgressBarAnimation from '../progressBarAnimation/progressBarAnimation';
import { isTablet } from 'react-device-detect';
import TouchPointFactory from '../../Common/MPCTouchPointFactory';
import { ScrollLock } from '../../../Helper/scrollLock';
import { BookmarkController } from '../globalState/BookmarkController';
import SortContextProvider from '../globalState/sortContextProvider';

export default class CategoryPage extends Component<ICategoryPageContext, ICategoryPageState> {
    private categoryRef = React.createRef<HTMLDivElement>();
    private gridRef = React.createRef<HTMLDivElement>()
    private articleTilesAjaxCaller: ArticleTilesAjaxCaller;
    private filterExist: boolean;
    private onlyHaveDeliveryTime: boolean;
    private touchPointFactory: TouchPointFactory;
    private globalState: GlobalState;
    private infinityScrollingIsLoading: boolean;
    private bookmarkController: BookmarkController;
    private originalPromotionTileHtmlString: Map<string, string> = new Map<string, string>;
    private readonly promoTileApiPath = 'LegacyFragments/RenderableDynaDocFragment';

    constructor(props) {
        super(props);
        const adjustSalesDesignationViews = this.insertPromotionTile(
            [...this.props.initialSalesDesignationViews], 
            this.props.paging.pageNumber, this.props.paging.pageEndOffset);
        this.state = {
            salesDesignationViews: adjustSalesDesignationViews,
            pageNo: this.props.paging.pageNumber,
            pageEndOffset: this.props.paging.pageEndOffset,
            waitingForResponse: false,
            totalResultCount: this.props.paging.totalResultCount,
            totalPageCount: this.props.paging.totalPageCount,
            isBreadcrumbOnly: false,
            scrollPosition: 0,
            activeDropDownFilterName: '',
            isStickyHeaderVisible: false,
            toggleToUpdateFasBar: false,
            infiniteScrollUpMode: helper.isUrlWithPageQuery(this.props.url),
            infiniteScrollUpMinPageNo: this.props.paging.pageNumber,
            mounted: false,
            isCategoryBottomReached: false,
            originalPromotileHtml: new Map<string, string>,
        };

        // init global state
        const initSelectedFilters = convertFromServiceToReactWorld(
            props.filterContentViewModel.userSelectedFilters,
            props.filterContentViewModel.filterModel);
        this.globalState = new GlobalState(this.props.filterContentViewModel.filterModel, 
            initSelectedFilters, this.props.filterContentViewModel.seoSlug, this.props.filterContentViewModel.searchTerm, 
            this.props.filterContentViewModel.kiosk, this.props.magicLink);
        this.articleTilesAjaxCaller = new ArticleTilesAjaxCaller(this.globalState.Spinner, this.props.magicLink);
        const articleListController = new ArticleListController(this.articleTilesAjaxCaller, this.globalState.onNewArticlesLoaded);
        this.globalState.articleListController = articleListController;
        this.bookmarkController = new BookmarkController(
            this.props.initialSalesDesignationViews, this.globalState);

        this.touchPointFactory = TouchPointFactory.instance;
        this.props.filterContentViewModel.l10n = this.props.l10n;

        this.handleLoadArticles = this.handleLoadArticles.bind(this);
        this.stickyHeaderVisibleChange = this.stickyHeaderVisibleChange.bind(this);
        this.handleActiveDropDownFilterChange = this.handleActiveDropDownFilterChange.bind(this);
        this.globalState.registerOnArticlesChanged(this.onNewArticles.bind(this));
        this.infiniteScroll = this.infiniteScroll.bind(this);
        this.handleSortContextChange = this.handleSortContextChange.bind(this);
        this.filterExist = this.props.filterContentViewModel.filterModel &&
            this.props.filterContentViewModel.filterModel.filters &&
            this.props.filterContentViewModel.filterModel.filters
                .filter((x) => x.name !== Constants.DELIVERY_TIME).length > 1;
        this.onlyHaveDeliveryTime = this.filterExist &&
            this.props.filterContentViewModel.filterModel.filters
                .filter((x) => x.name !== Constants.DELIVERY_TIME).length === 0;

        if (typeof document !== 'undefined') {
            this.createTouchpoints = this.createTouchpoints.bind(this);
            document.addEventListener('DOMContentLoaded', this.createTouchpoints);
        }
    }

    componentDidMount(): void {
        if (window && window.location) {
            if (window.location.hash.startsWith('#ats-') || window.location.hash.startsWith('#PromotionTile_')) {
                const tile = window.document.body.querySelector(window.location.hash);
                if (tile)
                    tile.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' })
                //remove hash
                history.replaceState(null, null, window.location.pathname + window.location.search);
            }
        }

        if (window && window.location && window.location.search.indexOf('page=') >= 0) {
            const params = new URL(window.location.href).searchParams;
            params.delete('page');
            let newUrl = window.location.pathname;
            if (params.size > 0) {
                newUrl += '?' + params.toString();
            }
            history.replaceState(null, null, newUrl);
        }

        if (window) {
            this.props.promotionTiles?.forEach(tile => {
                const dom = document.querySelector(`[data-tile-key="${tile.dynaDocKey}"] .fas_fragment_ph`);
                this.originalPromotionTileHtmlString.set(tile.dynaDocKey, dom.innerHTML);
            });
            this.setState({ originalPromotileHtml: this.originalPromotionTileHtmlString });
        }

        this.setState({ mounted: true }, () => {
            this.props.filterContentViewModel.isBreadcrumbOnly = this.isBreadcrumbOnly;
            this.setState({
                isBreadcrumbOnly: this.props.filterContentViewModel.isBreadcrumbOnly,
            });
        });
    }

    private insertPromotionTile(origin: ISalesDesignationView[], pageNumber: number,
        previousPageEndOffset: number): ISalesDesignationView[] {
        const hasUnloadedPreviousPage = this.props.paging.pageEndOffset - this.props.initialSalesDesignationViews.length > 0;
        const loadingNextPage = this.state?.pageNo < pageNumber;
        const totalPagesCount = Math.ceil((this.props.paging.totalResultCount - 48) / 24) + 1;
        const isLastPage = pageNumber === totalPagesCount;

        if (this.props.promotionTiles?.length > 0) {
            // filter result page or small category or browser back to filter result page with unloaded page:
            if((!hasUnloadedPreviousPage && origin.length < 48) || 
                (hasUnloadedPreviousPage && origin.length < 24 && !isLastPage))
            {
                // collect pt, which fits into current tiles result:
                this.props.promotionTiles.forEach(promoTile => {
                    if(promoTile.index <= origin.length) {
                        const placeHolder: ISalesDesignationView = this.createPromoTilePlaceHolder(pageNumber, promoTile.dynaDocKey, promoTile.index);
                        origin.splice(promoTile.index - 1, 0, placeHolder);
                    }
                });

                // pick first closest pt and add it at the very end of current result:
                const suitableLastPt = this.props.promotionTiles.filter(it=>it.index >= origin.length);

                if(suitableLastPt && suitableLastPt.length > 0) {
                    const lastPt = suitableLastPt.sort((a, b) => a.index - b.index)[0];

                    const placeHolder: ISalesDesignationView = this.createPromoTilePlaceHolder(pageNumber, lastPt.dynaDocKey, lastPt.index);
                    origin.push(placeHolder);
                }

                return origin;
            } else {
                // after loading page one
                if (pageNumber === 1) {
                    this.props.promotionTiles.forEach(promoTile => {
                        if (promoTile.index <= 48) {
                            const placeHolder: ISalesDesignationView = this.createPromoTilePlaceHolder(pageNumber, promoTile.dynaDocKey, promoTile.index);
                            origin.splice(promoTile.index - 1, 0, placeHolder);
                        }
                    });
                    return origin;
                // browser back to page != 1 and not loading next page
                } else if (hasUnloadedPreviousPage && !loadingNextPage) {
                    const previousPageStartOffset = previousPageEndOffset - 24;
                    let numberPreviousPagePromotionTiles = 0
                    this.props.promotionTiles.forEach((promoTile) => {
                            if (promoTile.index <= previousPageStartOffset) {
                                numberPreviousPagePromotionTiles++
                            }
                        }
                    )
                    this.props.promotionTiles.forEach((promoTile) => {
                        if ((promoTile.index > previousPageStartOffset) && (promoTile.index <= previousPageEndOffset)) {
                            const placeHolder: ISalesDesignationView = this.createPromoTilePlaceHolder(pageNumber, promoTile.dynaDocKey, promoTile.index);
                            // special case: origin is only lazy loaded and therefore does not hold all the tiles
                            origin.splice((promoTile.index - (previousPageStartOffset + numberPreviousPagePromotionTiles)) - 1, 0, placeHolder);
                        }
                    });
                    return origin;
                // after load more articles
                } else {
                    const offset = (pageNumber - 1) * 24 + 48;
                    this.props.promotionTiles.forEach(promoTile => {
                        if ((promoTile.index > previousPageEndOffset) && (promoTile.index <= offset + 24)) {
                            const placeHolder: ISalesDesignationView = this.createPromoTilePlaceHolder(pageNumber, promoTile.dynaDocKey, promoTile.index);
                            origin.splice(promoTile.index - 1, 0, placeHolder);
                        }
                    });
                    return origin;
                }
            }
        } else
            return origin;
    }

    private createPromoTilePlaceHolder(pageNumber: number, key: string, promoTileIndex): ISalesDesignationView {
        return {
            pageNo: pageNumber,
            defaultColorCode: null,
            processCode: null,
            showAvailableSizes: null,
            defaultSalesArticleNo: null,
            showColorSwitcher: null,
            minimalPrice: null,
            originalPrice: null,
            priceDeduction: null,
            comparisonPrice: null,
            description: null,
            salesArticleVariantColors: null,
            priceType: null,
            pdpLinkDefaultColor: null,
            salesArticleType: null,
            seoSlug: null,
            navigationKey: null,
            masterArticleNo: null,
            variantCountInfo: null,
            tileId: 'PromotionTile_' + key,
            flagAdvertisedAsCostFreeInSet: null,
            telemetryData: null,
            elementRef: React.createRef<HTMLDivElement>(),
            packagingInformation: null,
            isPromotionTile: true,
            promotionTileKey: key,
            promotionTileIndex: promoTileIndex
        }
    } 

    private infiniteScroll() {
        if (
            this.state.infiniteScrollUpMode && // we have returned from somwhere to ATS
            this.state.infiniteScrollUpMinPageNo > 1 && // there are missing pages still
            !this.infinityScrollingIsLoading // we dont currently wait for an older load
        ) {
            const lastScrollHeight = window.document.body.scrollHeight;
            this.infinityScrollingIsLoading = true;
            this.articleTilesAjaxCaller.loadMoreArticles(
                this.state.infiniteScrollUpMinPageNo - 1,
                this.props.kiosk,
                this.props.filterContentViewModel.viewType,
                false
            ).then((data) => {
                this.infinityScrollingIsLoading = false;
                const adjustSalesDesignationViews = this.insertPromotionTile([...data.articles], 
                    this.state.infiniteScrollUpMinPageNo - 1, this.state.pageEndOffset - 24);
                this.setState({
                    salesDesignationViews: adjustSalesDesignationViews.concat(this.state.salesDesignationViews), //prepend
                    infiniteScrollUpMinPageNo: this.state.infiniteScrollUpMinPageNo - 1,
                }, () => {
                    this.bookmarkController.updateArticleList(data.articles);

                    this.touchPointFactory.createTouchPointsBatch(data.articles);
                    // we want to keep the scroll position
                    setTimeout(() => {
                        const heightDifference = window.document.body.scrollHeight - lastScrollHeight;
                        window.scrollBy({ top: heightDifference });
                    }, 100);
                });
            }).catch(() => {
                this.infinityScrollingIsLoading = false;
            });
        }
    }

    private createTouchpoints(): void {
        this.touchPointFactory.createTouchPointsBatch(this.props.initialSalesDesignationViews);
    }

    componentWillUnmount(): void {
        if (typeof document !== 'undefined') {
            document.removeEventListener('DOMContentLoaded', this.createTouchpoints);
        }

        this.setState({
            mounted: false
        });
    }

    public onNewArticles(result: IFilteringResult) {
        if (!this.state.mounted)
            return;

        const adjustSalesDesignationViews = this.insertPromotionTile([...result.articles], 1,
            this.state.pageEndOffset);
        this.setState({
            salesDesignationViews: adjustSalesDesignationViews,
            totalResultCount: result.totalResultCount,
            totalPageCount: result.totalPageCount,
            pageEndOffset: result.pageEndOffset,
            pageNo: 1,
            infiniteScrollUpMode: false,
            waitingForResponse: false,
            toggleToUpdateFasBar: !this.state.toggleToUpdateFasBar,
            // progress bar is filled 100% if there are less then 5 articles:
            scrollPosition: result.totalResultCount > 0 && result.totalResultCount < 5 ? 100 : 0
        });
        ScrollLock.instance.checkSliderPosition();
    }

    public stickyHeaderVisibleChange(visible: boolean) {
        if (visible && !this.state.isStickyHeaderVisible) {
            this.setState({ activeDropDownFilterName: '' });
        }
        if (visible !== this.state.isStickyHeaderVisible) {
            this.setState({ isStickyHeaderVisible: visible });
        }
    }

    private get isBreadcrumbOnly(): boolean {
        if (this.props.filterContentViewModel.isMobile ||
            this.props.filterContentViewModel.isTablet || isTablet)
            return false;
        else {
            const isSpecialCategoryPage: boolean =
                this.props.filterContentViewModel.navigationKey === 'SALE'
                || this.props.filterContentViewModel.navigationKey === 'Neuheiten';

            const isKioskFilter = !!this.props.kiosk;

            if (isKioskFilter)
                return false;

            return this.filterExist ? isSpecialCategoryPage || this.onlyHaveDeliveryTime
                : true;
        }
    }

    private handleSortContextChange(shouldForceScroll) {
        this.globalState.updateArticleList(shouldForceScroll);
    }

    public render() {
        const mspNeeded = !this.props.category.hideBreadcrumbAndFilterBar || !this.props.category.hideStickyHeader;
        const applyAdditionalPadding = this.filterExist ? this.onlyHaveDeliveryTime
            : true;

        const isNewsLetterVisible = !(this.props.kiosk || this.props.filterContentViewModel.isMobile)
            && this.state.isStickyHeaderVisible
            && this.state.salesDesignationViews.length > 4;

        const shouldShowProductFinder = this.props.filterContentViewModel.productFinders
            && this.props.filterContentViewModel.productFinders.length > 0;
        return (
            <>
                {
                    this.props.promotionTiles && this.props.promotionTiles.length > 0 &&
                    <div className={styles.promo_tiles_container}>
                            {
                                this.props.promotionTiles.map((tile) => {
                                    const srcWithKey = 
                                        `Frontend-${this.props.filterContentViewModel.portal}`+
                                        `${this.props.filterContentViewModel.culturePrefix}${this.promoTileApiPath}`+
                                        `?documentkey=${tile.dynaDocKey}`;
                                    return (
                                        <div className={styles.promo_tile} data-tile-key={tile.dynaDocKey} key={tile.index}>
                                            <FragmentPlaceholder src={srcWithKey} ignoreErrors={true} />
                                        </div>);
                                })
                            }
                    </div>
                }

                <SortContextProvider
                    viewType={this.props.filterContentViewModel.viewType}
                    l10n={this.props.filterContentViewModel.l10n}
                    glovesFilter={this.globalState.filterModel
                        .filters?.find((x) => x.name === Constants.GLOVES)}
                    initialSort={this.props.filterContentViewModel.initialSort}
                    isMobile={this.props.filterContentViewModel.isMobile}
                    updateArticleList={this.handleSortContextChange}
                    magicLink={this.props.magicLink}
                >
                    <div className={styles.categorypage} ref={this.categoryRef} data-testid='categorypage'>
                        {mspNeeded &&
                            <MainSidePanel
                                shouldShowProductFinder={shouldShowProductFinder}
                                productFinderServicesUpAndRunning={this.props.category.productFinderServicesUpAndRunning}
                                filterContentViewModel={this.props.filterContentViewModel}
                                globalState={this.globalState}
                                l10n={this.props.l10n}
                                productFinderKey={shouldShowProductFinder ? this.props.filterContentViewModel.productFinders[0].key : ''
                                }
                            />
                        }
                        <div className={styles.header}>
                            <div className={classnames(styles.breadcrumb_fragment_container,
                                { [styles.is_breadcrumb_only]: this.state.isBreadcrumbOnly },
                                { [styles.is_mobile]: this.props.filterContentViewModel.isMobile },
                                { [styles.additional_padding]: applyAdditionalPadding })}>
                                {!this.props.category.hideBreadcrumbAndFilterBar &&
                                    // eslint-disable-next-line max-len
                                    <FragmentPlaceholder
                                        src={'ESPP.Breadcrumb/breadcrumb?pageType=Category&seoSlug=' +
                                            this.props.category.seoSlug + '&containerId=ats_cp_main_breadCrumb&showH1Tag=' +
                                            this.props.showH1Tag +'&addNavKeyPathMeta=true'}
                                        ignoreErrors={true}
                                        timeout={2}
                                    />
                                }
                                {(!this.props.category.hideBreadcrumbAndFilterBar && this.state.mounted) &&
                                    this.state.isBreadcrumbOnly &&
                                    <FasButton wwsId={this.props.kiosk ? this.props.kiosk.retailStoreNo.toString() : ''}
                                        {...this.props.filterContentViewModel} componentKey={1} globalState={this.globalState} />
                                }
                            </div>
                            {
                                this.props.filterContentViewModel && this.props.filterContentViewModel.filterModel &&

                                <div id='ats_scope-fas-bar' className='fas_scope-fas'>
                                    {(!this.props.category.hideBreadcrumbAndFilterBar) &&
                                        <FasBar
                                            key={'fasBar_1'}
                                            {...this.props.filterContentViewModel}
                                            dropDownFilterChange={this.handleActiveDropDownFilterChange}
                                            activeDropDownFilterName={!this.state.isStickyHeaderVisible ? this.state.activeDropDownFilterName : ''}
                                            globalState={this.globalState}
                                            toggleToUpdateFasBar={this.state.toggleToUpdateFasBar} />
                                    }
                                    {this.state.totalResultCount > 0 &&
                                        !this.props.category.hideBreadcrumbAndFilterBar &&
                                        <ProgressBarAnimation
                                            scrollPosition={this.state.scrollPosition}
                                            pageEndOffset={this.state.pageEndOffset}
                                            salesDesignationViews={this.state.salesDesignationViews}
                                            totalResultCount={this.state.totalResultCount}
                                            infiniteScroll={this.infiniteScroll}
                                        />
                                    }
                                </div>
                            }
                        </div>
                        {!this.props.category.hideStickyHeader &&
                            <StickyHeader isBreadcrumbOnly={this.state.isBreadcrumbOnly} isEsApp={this.props.filterContentViewModel.isEsApp}
                                isGlobal={this.props.filterContentViewModel.isGlobal} gridRef={this.gridRef}
                                visibleCallBack={this.stickyHeaderVisibleChange}
                                renderNLP={!this.props.filterContentViewModel.isMobile}
                                nlpIsVisibleByParentRules={isNewsLetterVisible}>
                                {
                                    <div className={classnames(styles.breadcrumb_fragment_container,
                                        { [styles.is_mobile]: this.props.filterContentViewModel.isMobile },
                                        { ['fas_is_global']: this.props.filterContentViewModel.isGlobal === true }
                                    )}>
                                        {
                                            // eslint-disable-next-line max-len
                                            <FragmentPlaceholder
                                                src={'ESPP.Breadcrumb/breadcrumb?pageType=Category&seoSlug=' +
                                                    this.props.category.seoSlug + '&containerId=ats_sh_cp_breadCrumb'}
                                                ignoreErrors={true}
                                                timeout={2}
                                            />
                                        }
                                        {
                                            this.state.isBreadcrumbOnly &&
                                            <FasButton wwsId={this.props.kiosk ? this.props.kiosk.retailStoreNo.toString() : ''}
                                                {...this.props.filterContentViewModel} componentKey={2} globalState={this.globalState} />
                                        }

                                    </div>
                                }
                                {
                                    (this.props.filterContentViewModel && this.props.filterContentViewModel.filterModel) &&
                                    <div id='ats_sticky-fas-bar' className='fas_scope-fas'>
                                        <FasBar key={'fasBar_2'} {...this.props.filterContentViewModel}
                                            dropDownFilterChange={this.handleActiveDropDownFilterChange}
                                            activeDropDownFilterName={this.state.isStickyHeaderVisible ? this.state.activeDropDownFilterName : ''}
                                            globalState={this.globalState}
                                            toggleToUpdateFasBar={this.state.toggleToUpdateFasBar}
                                        />
                                        {!this.props.category.hideBreadcrumbAndFilterBar &&
                                            <ProgressBarAnimation
                                                scrollPosition={this.state.scrollPosition}
                                                pageEndOffset={this.state.pageEndOffset}
                                                salesDesignationViews={this.state.salesDesignationViews}
                                                totalResultCount={this.state.totalResultCount}
                                                infiniteScroll={this.infiniteScroll}
                                            />
                                        }
                                    </div>
                                }
                            </StickyHeader>
                        }
                        <div className={styles.srp_top}></div>

                        <ArticleTileGrid
                            articles={this.state.salesDesignationViews}
                            currencyInformation={this.props.currencyInformation}
                            category={this.props.category}
                            kiosk={this.props.kiosk}
                            l10n={this.props.l10n}
                            isMobile={this.props.filterContentViewModel.isMobile}
                            isGlobal={this.props.filterContentViewModel.isGlobal}
                            isFilterDropDownOpen={!!this.state.activeDropDownFilterName}
                            viewType={this.props.filterContentViewModel.viewType}
                            culture={this.props.filterContentViewModel.culture}
                            portal={this.props.filterContentViewModel.portal}
                            gridRef={this.gridRef}
                            gridNumber={0}
                            articleTilesAjaxCaller={this.articleTilesAjaxCaller}
                            globalState={this.globalState}
                            addItemOriginToPdpLink={false}
                            singleArticleView={this.state.salesDesignationViews && this.state.salesDesignationViews.length === 1}
                            originalPromotileHtmlstring={this.state.originalPromotileHtml}
                        />
                        {this.state.totalResultCount > 0 &&
                            <Paging
                                viewedArticleNumber={this.state.pageEndOffset}
                                totalArticleCount={this.state.totalResultCount}
                                waitingForResponse={this.state.waitingForResponse}
                                onLoadMore={this.handleLoadArticles} l10n={this.props.l10n}
                                totalPagesNumber={this.state.totalPageCount}
                                currentPageNumber={this.state.pageNo}
                                url={this.props.url}
                                showHiddenLinks={true} />
                        }
                    </div>
                </SortContextProvider>
            </>
        );
    }

    public handleActiveDropDownFilterChange = (prActiveDropDownFilter: string) => {
        this.setState({
            activeDropDownFilterName: prActiveDropDownFilter,
        });
    }

    public handleLoadArticles() {
        this.setState({ waitingForResponse: true });
        this.articleTilesAjaxCaller
            .loadMoreArticles(
                this.state.pageNo + 1,
                this.props.kiosk,
                this.props.filterContentViewModel.viewType,
                true)
            .then((data) => {
                if (data && Array.isArray(data.articles)) {
                    const mergedList = this.state.salesDesignationViews.concat(data.articles)
                    const adjustSalesDesignationViews = this.insertPromotionTile([...mergedList], 
                        this.state.pageNo + 1, this.state.pageEndOffset);

                    this.setState({
                        pageNo: this.state.pageNo + 1,
                        pageEndOffset: data.pageEndOffset,
                        salesDesignationViews: adjustSalesDesignationViews,
                    }, () => {
                        const scrollPos = helper.calculateScrollDistance(
                            this.state.pageEndOffset,
                            this.state.salesDesignationViews);
                        this.setState({ scrollPosition: scrollPos });
                        this.bookmarkController.updateArticleList(data.articles);

                        // call mpc for tp upgrade
                        this.touchPointFactory.createTouchPointsBatch(data.articles);

                        this.props.paging.pageNumber = this.state.pageNo;
                    });
                }
                this.setState({ waitingForResponse: false })
            });
    }
}
